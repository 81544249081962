/* Chat Container */
.chat-container {
    max-width: 600px;
    margin: 20px auto;
    background: white;
    border-radius: 15px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
    font-family: "Arial", sans-serif;
}

/* Chat Header */
.chat-header {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 15px;
    color: #4a4a4a;
}

/* Chat Messages */
.chat-messages {
    max-height: 400px;
    overflow-y: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

/* Message Bubbles */
.message {
    padding: 12px;
    margin: 8px 0;
    border-radius: 12px;
    max-width: 75%;
    word-wrap: break-word;
    transition: 0.3s ease;
}

/* User Message (Right-aligned) */
.user-message {
    background: #4caf50;
    color: white;
    align-self: flex-end;
    text-align: right;
}

/* Angel Message (Left-aligned) */
.angel-message {
    background: #f1f1f1;
    color: black;
    align-self: flex-start;
}

/* Chat Input */
.chat-input {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ddd;
    background: #fff;
}

.chat-input input {
    flex: 1;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 20px;
    font-size: 16px;
    outline: none;
    transition: border 0.3s;
}

.chat-input input:focus {
    border-color: #4caf50;
}

.chat-input button {
    background: #4caf50;
    color: white;
    border: none;
    padding: 12px 20px;
    margin-left: 8px;
    cursor: pointer;
    border-radius: 20px;
    font-size: 16px;
    transition: 0.3s ease;
}

.chat-input button:hover {
    background: #45a049;
}

/* Scrollbar Customization */
.chat-messages::-webkit-scrollbar {
    width: 5px;
}

.chat-messages::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 5px;
}

/* Typing Indicator */
.angel-message::after {
    content: " ✨";
    animation: typingDots 1.5s infinite;
}

@keyframes typingDots {
    0% { content: " ✨"; }
    33% { content: " ✨✨"; }
    66% { content: " ✨✨✨"; }
    100% { content: " ✨"; }
}

/* Angel Selection Dropdown */
.angel-selection {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    padding: 10px;
    background: #f8f8f8;
    border-radius: 8px;
}

.angel-selection label {
    font-weight: bold;
    margin-right: 10px;
    color: #333;
}

.angel-selection select {
    padding: 8px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background: white;
    cursor: pointer;
}

.angel-selection select:hover {
    border-color: #4caf50;
}